<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            v-if="false"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="false"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                :to="{name: 'organization-script-add'}"
              >
                <span class="text-nowrap">{{ $t('script.scripts.btn.add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refScriptListTable"
        primary-key="id"
        responsive
        :items="scripts"
        :fields="tableColumns"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        show-empty
        :empty-text="loading ? $t('shared.loading') : $t('shared.no-data')"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            variant="flat-primary"
            :to="{name: 'organization-script-edit', params: {id: data.item.id}}"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('shared.pagination-summary', dataMeta) }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalItems > perPage"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import useScript from './useScript'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,

    vSelect,
  },
  data() {
    return {
      // Table Handlers
      tableColumns: [
        { key: 'subject', label: this.$t('script.scripts.label.subject'), sortable: true },
        { key: 'scriptGroup.name', label: this.$t('script.scripts.label.script-group'), sortable: true },
        { key: 'actions', label: this.$t('shared.actions'), sortable: false },
      ],
    }
  },

  setup() {
    const {
      loading,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refScriptListTable,
      fetchScripts,
      scripts,
    } = useScript()

    return {
      loading,

      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refScriptListTable,
      fetchScripts,
      scripts,

      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
