import { ref, computed } from '@vue/composition-api'

// Notification
import store from '@/store'

export default function useScript() {
  const refScriptListTable = ref(null)

  const loading = ref(false)
  const perPage = ref(10)
  const totalItems = computed(() => store.state.script.scripts.length)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const scripts = computed(() => store.state.script.scripts)
  const dataMeta = computed(() => ({
    from: perPage.value * (currentPage.value - 1) + (totalItems.value ? 1 : 0),
    to: totalItems.value < perPage.value ? totalItems.value : perPage.value * currentPage.value,
    of: scripts.value.length,
  }))

  // watch([currentPage, perPage, searchQuery], () => {
  //   refetchData()
  // })

  const fetchScripts = () => {
    loading.value = true
    store.dispatch('script/fetchScripts')
      .finally(() => {
        loading.value = false
      })
  }

  fetchScripts()

  return {
    loading,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refScriptListTable,
    fetchScripts,
    scripts,
  }
}
